import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AirportEntity } from 'src/app/home/entities/airport-entity.model';
import { HomeService } from 'src/app/home/shared/home.service';
import { HolidaysService } from 'src/app/services/holidays.service';
import { ToasterService } from 'src/app/services/toastr-service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-holidays-search-result',
  templateUrl: './holidays-search-result.component.html',
  styleUrls: ['./holidays-search-result.component.scss']
})
export class HolidaysSearchResultComponent implements OnInit {
  visible: boolean = false;
  mainImageSrc = '../../../assets/images/umrah/umrah1.jpg';
  

  priceNight: boolean;
  Package: boolean;
  Numofdays: boolean; 
  displayPrice: string;
  displayPackage: string;
  
  displaydays: string;

  destinationCountry: string = 'Pakistan'; // Default country
  
  originCountry: string = 'Pakistan'; // Default country
  popularCities: any;
  airports: any;
  filteredAirports: any[];

  searchResults: any[] = [];
  selectedPriceRange: string;
  selectedPackage: string;
  selectedNumofdays: string;
  destinationLocation: string;
  originLocation: string;

  constructor(private router: Router,
    private holidaysService: HolidaysService,
    private toasterService: ToasterService,
    private homeService: HomeService,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.selectedPriceRange = 'Any'; // Default price range
    this.selectedPackage = 'All Packages'; 
    this.selectedNumofdays = 'All Duration'; 
    this.displayPrice = `Price Range: ${this.selectedPriceRange}`;
    this.displayPackage = `${this.selectedPackage}`;
    this.displaydays = `${this.selectedNumofdays}`;
  
    this.route.queryParams.subscribe(params => {
      console.log('Query Parameters:', params);
  
      this.originLocation = params['originLocation'] || 'Default Origin';
      this.destinationLocation = params['destinationLocation'] || 'Default Destination';
      this.selectedPriceRange = params['priceRange'] || 'Any';
      this.selectedPackage = params['package'] || 'All Packages';
      this.selectedNumofdays = params['numOfDays'] || 'All Duration';
  
      console.log('Origin Location:', this.originLocation);
      console.log('Destination Location:', this.destinationLocation);
      console.log('Selected Price Range:', this.selectedPriceRange);
      console.log('Selected Package:', this.selectedPackage);
      console.log('Selected Number of Days:', this.selectedNumofdays);
  
      this.displayPrice = `Price Range: ${this.selectedPriceRange}`;
      this.displayPackage = `${this.selectedPackage}`;
      this.displaydays = `${this.selectedNumofdays}`;
    });
  
    this.getAirports();
    this.searchResults = this.holidaysService.getSearchResults();
    console.log("searchResults", this.searchResults); // Verify the data
  
    document.addEventListener('click', (e) => {
      const ignoreIds = [
        'price-h2',
        'days-h2',
        'Package-h2',
        'departureDataH5',
      ];
      //@ts-ignore
      if (ignoreIds.includes(e.srcElement.id)) return;
      this.priceNight = false;
      this.Package = false;
      this.Numofdays = false;
    });
  }
 
  departureClick() {
    document.getElementsByClassName('select2')[0]?.classList.remove('select2')
    document.getElementById('locationContainer1').classList.toggle('select1')
    document.getElementById('txtFrom').focus();

   
  }

  arrivalClick() {
    document.getElementsByClassName('select1')[0]?.classList.remove('select1')
    document.getElementById('locationContainer2').classList.toggle('select2')
    document.getElementById('txtTo').focus();

  }

  selectedDestination(location: string) {
    this.destinationLocation = location;
    this.destinationCountry = this.getCountry(location.split(',')[0]); // Update the country when location is set
    document.getElementById('locationContainer2').classList.remove('select2');
  }
  selectedOrigin(location: string) {
    this.originLocation = location;
    this.originCountry = this.getCountry(location.split(',')[0]); // Update the country when location is set
    document.getElementById('locationContainer1').classList.remove('select1');
  }
  searchResult() {
    // Check if default values are selected
    if (
      this.selectedPriceRange === 'Any' &&
      this.selectedPackage === 'All Packages' &&
      this.selectedNumofdays === 'All Duration'
    ) {
      // Call the API to get all records
      this.holidaysService.getTripPackages().subscribe((response) => {
        if (response && response.status === 'success') {
          if (response.data && response.data.length > 0) {
            console.log("response", response);
            this.searchResults=response.data;
         
          } else {
            this.toasterService.showError(
              'Data not found.',
              'Error'
            );
          }
        } else {
          if (response.message && response.message !== null && response.message !== '') {
            this.toasterService.showError(
              'Record not found Please try again.',
              'Error'
            );
          }
        }
      });
    } else {
      // Construct the body object for search API call
      let body: any = {
        destinationLocation: this.destinationLocation,
        originLocation :this.originLocation,
        starCategory: this.selectedPackage,
      };

      if (this.selectedPriceRange !== 'Any') {
        const [minPrice, maxPrice] = this.selectedPriceRange.split('-').map(Number);
        body.minPrice = minPrice;
        body.maxPrice = maxPrice;
      }

      if (this.selectedNumofdays !== 'All Duration') {
        const [minDuration, maxDuration] = this.selectedNumofdays.split('-').map(Number);
        body.minDuration = minDuration;
        body.maxDuration = maxDuration;
      }

      // Call the search API
      this.holidaysService.searchtrip(body).subscribe((response) => {
        if (response && response.status === 'success') {
          if (response.data && response.data.length > 0) {
            console.log("response", response);
            this.searchResults=response.data;
          } else {
            this.toasterService.showError(
              'Data not found.',
              'Error'
            );
          }
        } else {
          if (response.message && response.message !== null && response.message !== '') {
            this.toasterService.showError(
              'Record not found Please try again.',
              'Error'
            );
          }
        }
      });
    }
  }
  dialogClosed(): void {
    
    this.visible = false;
   
  }
  opendialog(){
    this.visible = true;
  }
  details(id: any) {
    console.log("id",id)
    const url = this.router.serializeUrl(
        this.router.createUrlTree(['/holiday/package-details', id])
    );
    window.open(url, '_blank');
}
  getAirports() {
    this.airports = new Array<AirportEntity>();
    this.homeService.getPopularAirports().subscribe((response) => {
      if (response) {
        if (response.status === 'success') {
          if (response.data) {
            this.airports = response.data;
            this.popularCities = response.data;
            this.filteredAirports = response.data; // Initialize filteredAirports with all airports
          }
        } else {
          if (response.message) {
            this.toasterService.showError(
              'Airports are not loaded! Please try again.',
              'Error'
            );
          } else {
            this.toasterService.showError(
              'Airports are not loaded! Please try again.',
              'Error'
            );
          }
        }
      } else {
        this.toasterService.showError(
          'Airports are not loaded! Please try again.',
          'Error'
        );
      }
    });
  }
  getCountry(city: string): string {
    const airport = this.airports.find((airport: AirportEntity) => airport.city === city);
    return airport ? airport.country : '';
  }

  filterAirports() {
    const searchTerm = this.destinationLocation.toLowerCase();
    this.filteredAirports = this.airports.filter((airport: AirportEntity) =>
      airport.city.toLowerCase().includes(searchTerm) || 
      airport.country.toLowerCase().includes(searchTerm)
    );
  }

  selectPriceRange(priceRange: string) {
    this.selectedPriceRange = priceRange;
    this.displayPrice = `Price Range: ${this.selectedPriceRange}`;
    this.priceNight = false; // Close the Price Per Night section
  }

  selectPackage(Package: string) {
    this.selectedPackage = Package;
    this.displayPackage = `Package: ${this.selectedPackage}`;
    this.Package = false; // Close the Package section
  }

  selectdays(Numofdays: string) {
    this.selectedNumofdays = Numofdays;
    this.displaydays = `Number of Days: ${this.selectedNumofdays}`;
    this.Numofdays = false; // Close the Duration section
  }

  nightopen() {
    this.priceNight = !this.priceNight;
    this.Numofdays = false;
    this.Package = false;
  }

  Packageopen() {
    this.Package = !this.Package;
    this.Numofdays = false;
    this.priceNight = false;
  }

  NumofdaysOpen() {
    this.Numofdays = !this.Numofdays;
    this.Package = false;
    this.priceNight = false;
  }
}

