<!-- <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-8">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="assets/images/hotel/m.jpg"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="assets/images/hotel/m2.jpg"
                alt="Second slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="assets/images/hotel/m1.jpg"
                alt="Third slide"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
  
        <div class="carousel-header">
          <h2>Jabal Omar Marriott Hotel Makkah</h2>
  
          <table class="boking_tbl">
            <tbody>
              <tr class="boking_trl">
                <th>Makkah</th>
                <th>Madina</th>
                <td class="tbl_txt1">&nbsp;</td>
              </tr>
              <tr class="boking_tb2">
                <td>Abeer Al-Fadilah</td>
                <td>Fundaq Saeeda</td>
                <td class="boking_td1">
                  <span>
                    <i class="fa fa-building" aria-hidden="true"></i> Hotel
                  </span>
                </td>
              </tr>
              <tr class="boking_tb3">
                <td>1800 meter</td>
                <td>1100 meter</td>
                <td class="boking_td1">
                  <span>
                    <i class="fa fa-road" aria-hidden="true"></i> Distance
                  </span>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>5</td>
                <td class="boking_td1">
                  <span>
                    <i class="fa fa-sun-o" aria-hidden="true"></i> No.of Nights
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-4 border-radius">
        <div class="info-box">
          <h2>Superior Queen Room</h2>
          <div class="d-flex justify-content-between">
            <div class="info-item">
              <p>Sharing Price</p>
              <h4>Rs 36,000</h4>
            </div>
            <div class="info-item">
              <p>Sharing Price</p>
              <h4>Rs 36,000</h4>
            </div>
          </div>
          
          <div class="prmRoomDtlCard__footer p-3">
            <a class="prmRoomDtlCard__link">VIEW OTHER ROOMS</a
            ><span class="bookNowBtn"
              ><a class="primaryBtn">BOOK THIS NOW</a></span
            >
          </div>
        </div>
        <div class="baseRight__item appendTop10">
          <div class="altViewOnMap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.5382510918125!2d74.32915597481565!3d31.564284544972512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904d22df9c961%3A0x52d708e1b45ccf92!2sMehman.pk!5e0!3m2!1sen!2s!4v1692038371308!5m2!1sen!2s"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="prmRatingCard">
            <div class="mt-auto mb-auto">
              <p class="font12 appendTop3">Based on <b> 302 Ratings</b></p>
              <p class="prmRatingCard__cta">
                <a class="font14 latoBlack capText">Read all Reviews</a>
              </p>
              
          </div>
        </div>
        <div class="agent-card">
          <div class="agent-heading">
            TRAVEL AGENCY DETAILS
        </div>
        <div class="d-flex">
          <div class="agent-image">
            <img src="assets/images/umrah/plan-logo.jpg" alt="Agent Image">
        </div>
        <div class="agent-details">
          
            <p>For more details call or</p>
            <p class="contact-number">WhatsApp: 0300-7800017</p>
        </div>
        </div>
         
      </div>
      </div>
    
    </div>
  
    <div class="card mt-2 mb-5">
      <p-tabView class="tabview">
        <p-tabPanel header="OVERVIEW">
          <h2>About The Hotel</h2>
          <p>
            Batoul Ajyad Hotel is located in Makkah. 1200 m away from Holy Haram
            and Abraj Albait Towers All Rooms are modern furnished and equipped
            with Split Air condition,and fitted with Wardrobe, Flat Screen TV,
            WIFI, Mini Fridge,Safety Deposit Box and Water Boiler for preparing
            hot drinks. All rooms comprises a private bathroom with a shower, free
            toiletries and a hair dryer.<br />
            Guests at Batoul Ajyad Hotel can enjoy Buffet breakfast.Coffee shop in
            Lobby area serves all kinds of drinks and snacks all day. Off-Site
            Parking Area is also available.Shuttle Bus Service to/from Holy Haram
            is available 24/24 Speaking Arabic, English and Urdu at the reception,
            staff are willing to help at any time of the day.<br />King Abdulaziz
            International Airport is 85 Km away from the hotel.
          </p>
        </p-tabPanel>
        <p-tabPanel header="PACKAGE DETAILS">
          <h2>Package Details</h2>
          <div class="row">
            <div class="col-md-6 col-lg-5 col-sm-12">
              <p>Agent Name</p>
              <p>Package Name</p>
              <p>Total Stay</p>
              <p>Makkah Stay</p>
              <p>Madina Stay</p>
              <p>Departure City</p>
              <p>Destination City</p>
            </div>
            <div class="col-md-6 col-lg-5 col-sm-12">
              <p>Mehman</p>
              <p>11 Days Economy Package</p>
              <p>11 Days</p>
              <p>5 Days</p>
              <p>5 Days</p>
              <p>All Airport</p>
              <p>Jeddah</p>
            </div>
          </div>
          <h2> 
  
            Hotel Details</h2>
          <div class="row">
            <div class="col-md-6 col-lg-5 col-sm-12">
              <p>Mehman</p>
              <p>Makkah Hotel Distance:</p>
              <p>Madinah Hotel Name:</p>
              <p>Madinah Hotel Distance:</p>
            </div>
            <div class="col-md-6 col-lg-5 col-sm-12">
              <p>Abeer Al Fadilah</p>
              <p>1800 meter</p>
              <p>Fundaq Saeeda</p>
              <p>1100 meter</p>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Facilities ">
          <h2>Amenities at Avari Xpress Gulberg</h2>
          <div class="AMENITIES">
            <p class="d-flex">
              <span class="fclty__header--iconWrapper appendRight12"
                ><img
                  src="//promos.makemytrip.com/images/highlighted/free_wi_fi.png"
                  alt="Free Wi-Fi"
                  title="" /></span
              ><span class="latoBold font16 mt-auto mb-auto ml-3"
                >Free Wi-Fi</span
              >
            </p>
            <p class="d-flex">
              <span class="fclty__header--iconWrapper appendRight12"
                ><i class="fa fa-check" aria-hidden="true"></i></span
              ><span class="latoBold font16 mt-auto mb-auto ml-3"
                >Elevator/Lift</span
              >
            </p>
            <p class="d-flex">
              <span class="fclty__header--iconWrapper appendRight12"
                ><i class="fa fa-check" aria-hidden="true"></i></span
              ><span class="latoBold font16 mt-auto mb-auto ml-3">Newspaper</span>
            </p>
            <p class="d-flex">
              <span class="fclty__header--iconWrapper appendRight12"
                ><i class="fa fa-check" aria-hidden="true"></i></span
              ><span class="latoBold font16 mt-auto mb-auto ml-3"
                >Laundry Service</span
              >
            </p>
          </div>
          <div class="AMENITIES-section p-2">
            <div class="">
              <p class="font16">Highlighted Amenities</p>
              <ul>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Free Wi-Fi</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Elevator/Lift</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Newspaper</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Laundry Service</span>
                </li>
              </ul>
            </div>
            <div class="">
              <p class="font16">Basic Facilities</p>
              <ul>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Free Wi-Fi</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Elevator/Lift</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Newspaper</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Laundry Service</span>
                </li>
              </ul>
            </div>
            <div class="">
              <p class="font16">Payment Services</p>
              <ul>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>ATM</span>
                </li>
              </ul>
            </div>
            <div class="">
              <p class="font16">Health and wellness</p>
              <ul>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Gym</span>
                </li>
              </ul>
            </div>
            <div class="">
              <p class="font16">General Services</p>
              <ul>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Luggage Storage</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Concierge</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Multilingual Staff</span>
                </li>
                <li class="font14 lineHight22 appendBottom3">
                  - <span>Ticket/Tour Assistance</span>
                </li>
              </ul>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="POLICIES">
          <p>In this section, we will give you the latest policy information revised by mehmanold.duckdns.org:</p>
          <ol class="p-4">
            <li>All above rates are SUBJECT TO AVAILABILITY basis.</li>
            <li>The policy information is updated regularly.</li>
            <li>If you are asked for your personal details to fill any form, we ensure you that it is safe with us and we will not share it with anyone without your consent. It may be required for signing up, subscriptions, for future endeavors, to approve requests or newsletters, etc. you may request to remove your profile information from the website or unsubscribe anytime.</li>
            <li>Mehman.net is a B2B website to connect the customers with the travel agents or Hajj, Umrah. The customer contacts for the required package and the agency submits the package details.</li>
            <li>If you are adding a review about some package, your personal data may be published to show your feedback so that other viewers may know, to what extent, that package is recommended.</li>
            <li>pk informs you the purpose of your personal data and ensures you for its integrity. We check the accuracy of information, you provide and also verify, whether it is up to date or not.</li>
            <li>To improve the user experience, mehmanold.duckdns.org uses cookies, which means the information of the user available on the device, he is using. However, we cannot recognize you on the basis of cookies only.</li>
            <li>Our website maintains the site statistics, i.e. how many visitors opened the site and their basic information, including the IP address, the type of browser and other such stats. But here it is important to clear that such information is collectively stored, not targeted individually and its purpose is to analyze the demographics, etc.</li>
            <li>pk may give the cookies information in aggregate to the travel agencies for the travel inquiry. This disclosure is allowed to us by law and it is a part of our agreement that you first accept and then proceed.</li>
            <li>pk contains links to other websites, but these external links are not a part of this site and we cannot be held responsible for their content. If you click on any of these links, you will follow their policy, not ours.</li>
            <li>For the security purposes, our team can do protection measures for your provided data. It includes the firewall and password related protection and also the regulations of authorized access. For the unauthorized access, an action can be taken.</li>
            </ol>
        </p-tabPanel>
        <p-tabPanel header="REVIEW">
          <div class="container-fluid mx-auto">
            <div class="row">
              <div class="col-xl-7 col-lg-8 col-md-10 col-12 text-center mb-5">
                <div class="reviewcard">
                  <div class="row d-flex">
                    <div class="">
                      <img
                        class="profile-pic"
                        src="https://i.imgur.com/V3ICjlm.jpg"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <h3 class="mt-2 mb-0">Mukesh Kumar</h3>
                      <div>
                        <p class="text-left">
                          <span class="text-muted">4.0</span>
                          <span class="fa fa-star star-active ml-3"></span>
                          <span class="fa fa-star star-active"></span>
                          <span class="fa fa-star star-active"></span>
                          <span class="fa fa-star star-active"></span>
                          <span class="fa fa-star star-inactive"></span>
                        </p>
                      </div>
                    </div>
                    <div class="ml-auto">
                      <p class="text-muted pt-5 pt-sm-3">10 Sept</p>
                    </div>
                  </div>
                  <div class="row text-left">
                    <h4 class="blue-text mt-3">
                      "An awesome activity to experience"
                    </h4>
                    <p class="content">
                      If you really enjoy spending your vacation 'on water' or
                      would like to try something new and exciting for the first
                      time.
                    </p>
                  </div>
                  <div class="row text-left">
                    <img class="pic" src="https://i.imgur.com/kjcZcfv.jpg" />
                    <img class="pic" src="https://i.imgur.com/SjBwAgs.jpg" />
                    <img class="pic" src="https://i.imgur.com/IgHpsBh.jpg" />
                  </div>
                  <div class="row text-left mt-4">
                    <div class="like mr-3 vote">
                      <img src="https://i.imgur.com/mHSQOaX.png" /><span
                        class="blue-text pl-2"
                        >20</span
                      >
                    </div>
                    <div class="unlike vote">
                      <img src="https://i.imgur.com/bFBO3J7.png" /><span
                        class="text-muted pl-2"
                        >4</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
   -->
   <div class="container" *ngIf="searchResults">
    <div class="row justify-content-between">
      <div class="col-md-8">
     
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src="assets/images/hotel/m.jpg"
                  alt="First slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="assets/images/hotel/m2.jpg"
                  alt="Second slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="assets/images/hotel/m1.jpg"
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
    
        
       
  
        <div class="carousel-header">
          <h2>{{ searchResults.tripName }}</h2>
          <table class="boking_tbl">
            <tbody>
              <tr class="boking_trl">
                <th>Origin Location</th>
                <th>Destination Location</th>
                <td class="tbl_txt1">&nbsp;</td>
              </tr>
              <tr class="boking_tb2">
                <td>{{ searchResults.originLocation }}</td>
                <td>{{ searchResults.destinationLocation }}</td>
               
              </tr>
              <tr class="boking_tb3">
                <td>Duration: {{ searchResults.duration }} days</td>
                <td>Star Category: {{ searchResults.starCategory }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-4 border-radius">
        <div class="info-box">
          <h2>{{ searchResults.tripName }}</h2>
          <div class="d-flex justify-content-between">
            <div class="info-item">
              <p>Price</p>
              <h4>Rs {{ searchResults.price }}</h4>
            </div>
          </div>
          <div class="prmRoomDtlCard__footer p-3">
            <a class="prmRoomDtlCard__link">VIEW OTHER ROOMS</a>
            <span class="bookNowBtn">
              <a class="primaryBtn" (click)="opendialog()">BOOK THIS NOW</a>
            </span>
          </div>
        </div>
        <div class="card flex justify-content-center dialog">
          <p-dialog
            header=""
            [(visible)]="visible"
            [style]="{ width: '90vw', maxWidth: '50rem' }"
            (onHide)="dialogClosed()"
          >
          <div class="popup-content text-center">
            <h2>For booking please contact this number</h2>
            <!-- Optionally, include the actual contact number -->
            <p>0325488818</p>
          </div>
          </p-dialog>
        </div>
        <!-- <div class="baseRight__item appendTop10">
          <div class="altViewOnMap">
            <iframe [src]="'https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=' + searchResults.tripHotels[0].lat + ',' + searchResults.tripHotels[0].long"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          <div class="prmRatingCard">
            <div class="mt-auto mb-auto">
              <p class="font12 appendTop3">Based on <b> 302 Ratings</b></p>
              <p class="prmRatingCard__cta">
                <a class="font14 latoBlack capText">Read all Reviews</a>
              </p>
            </div>
          </div>
        </div> -->
        <!-- <div class="agent-card">
          <div class="agent-heading">TRAVEL AGENCY DETAILS</div>
          <div class="d-flex">
            <div class="agent-image">
              <img src="assets/images/umrah/plan-logo.jpg" alt="Agent Image">
            </div>
            <div class="agent-details">
              <p>For more details call or</p>
              <p class="contact-number">WhatsApp: 0300-7800017</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  
    <div class="card mt-2 mb-5">
      <p-tabView class="tabview">
        <p-tabPanel header="OVERVIEW">
          <h2>About The Trip</h2>
         
          <div class="m-4" [innerHTML]="searchResults.tripDetails"></div>
        </p-tabPanel>
        <p-tabPanel header="PACKAGE DETAILS">
          <h2>Package Details</h2>
          <div class="row">
            <div class="col-md-6 col-lg-5 col-sm-12">
              <p>Agent Name: Mehman</p>
              <!-- <p>Package Name: 11 Days Economy Package</p> -->
              <p>Total Stay: {{ searchResults.duration }} Days</p>
              <p>Departure City: {{ searchResults.originLocation }}</p>
              <p>Destination City: {{ searchResults.destinationLocation }}</p>
            </div>
          </div>
          <h2>Hotel Details</h2>
          <div class="row">
            <div class="col-md-6 col-lg-5 col-sm-12">
              <p>Hotel Name: {{ searchResults.tripHotels[0].name }}</p>
              <p>Hotel Distance: {{ searchResults.tripHotels[0].long }} meters</p>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Facilities">
          <h2>Facilities</h2>
          <div class="AMENITIES">
            <p *ngFor="let facility of searchResults.tripFacilities" class="d-flex">
              <span class="fclty__header--iconWrapper appendRight12">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
              <span class="latoBold font16 mt-auto mb-auto ml-3">{{ facility.name }}</span>
            </p>
          </div>
        </p-tabPanel>
        <p-tabPanel header="POLICIES">
          <p>In this section, we will give you the latest policy information revised by mehmanold.duckdns.org:</p>
          <ol class="p-4">
            <li>All above rates are SUBJECT TO AVAILABILITY basis.</li>
            <li>The policy information is updated regularly.</li>
            <li>If you are asked for your personal details to fill any form, we ensure you that it is safe with us and we will not share it with anyone without your consent. It may be required for signing up, subscriptions, for future endeavors, to approve requests or newsletters, etc. you may request to remove your profile information from the website or unsubscribe anytime.</li>
            <li>Mehman.net is a B2B website to connect the customers with the travel agents or Hajj, Umrah. The customer contacts for the required package and the agency submits the package details.</li>
            <li>If you are adding a review about some package, your personal data may be published to show your feedback so that other viewers may know, to what extent, that package is recommended.</li>
            <li>pk informs you the purpose of your personal data and ensures you for its integrity. We check the accuracy of information, you provide and also verify, whether it is up to date or not.</li>
            <li>To improve the user experience, mehmanold.duckdns.org uses cookies, which means the information of the user available on the device, he is using. However, we cannot recognize you on the basis of cookies only.</li>
            <li>Our website maintains the site statistics, visits, user timings, clicked or searched data, duration of user on the site, device and other activities. This information is mainly used to increase the overall performance of the website for future needs and keep it up to date. If you are using our website, you allow us to monitor your online activity to some extent.</li>
          </ol>
        </p-tabPanel>
        <p-tabPanel header="TERMS & CONDITIONS">
          <h2>Terms & Conditions</h2>
          <ul class="p-4">
            <li>All Rates are non-refundable.</li>
            <li>Any extra expense due to a major change in itinerary by the passenger (like air ticket amendment, etc.) will be the responsibility of the passenger.</li>
            <li>All Rates are as per above quoted itinerary, any further change will be charged accordingly.</li>
            <li>Refunds will not be available in case of no show, late boarding, or any other circumstances.</li>
            <li>Refunds are subject to airline or hotel policy.</li>
            <li>After commencement of the journey, no refund, partial or otherwise, will be available.</li>
            <li>All Rates are net & non-commissionable.</li>
            <li>Booking confirmations are subject to availability at the time of booking.</li>
          </ul>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  