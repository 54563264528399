<section class="hero-headermain">
  <div class="container padd-right"> 
    <!-- <button  type="button">Modify</button> -->
    <!-- <div class="modifyButton text-white d-flex justify-content-between" *ngIf="isShow">
        
              <div class="modifyButton d-lg-none" *ngIf="locationResult">
                {{ originLocation }}
                <a><img src="assets/images/home/exchange.svg" class="img-fluid mx-2" alt="" /></a>{{ destinationLocation }}
                <a class="mx-1 py-2 text-white bg-success px-2 rounded">{{
                  leaveDate | date : "MMMM d, y"
                  }}</a>
              </div>
              <div class="modifyButton d-flex justify-content-end d-lg-none" (click)="showSearch()" *ngIf="!isdisplayed">
                <a>Modify</a>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen"
                  viewBox="0 0 16 16">
                  <path
                    d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                </svg>
              </div>
            </div> -->
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div id="search-div" class="search-result-box wow fadeInUp d-xs-none">
          <div class="book-box">
            <ul>
              <li id="liDeparture" data-trigger="manual" data-toggle="popover" data-placement="top" data-content="<span><i class='fa fa-exclamation-triangle'></i> Enter valid From airport</span>">
                <a (click)="departureClick()">
                  <div class="book-details book-details1" id="locationDataDiv">
                    <h5 id="departureDataH5">From</h5>
                    <input id="departureDataInout" type="text" class="mehman-inputfiled" [value]="originLocation " readonly />
                    <p id="departureDataP">{{ originCountry }}</p>
                  </div>
                </a>
                <div class="inputfiled-formboxes" id="locationContainer1">
                  <div class="inputfiled-formbox">
                    <div class="input-search">
                      <input type="text" id="txtFrom" class="input-from" placeholder="From" [(ngModel)]="originLocation" (ngModelChange)="filterAirports()" />
                      <div class="search-icon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <ul>
                      <li><p class="recent-tag">Popular Cities</p></li>
                      <li *ngFor="let airport of filteredAirports" role="option" style="cursor: pointer" (click)="selectedOrigin(airport.city)">
                        <div class="hrtlCenter">
                          <div class="search-icon">
                            <i class="fa fa-plane" aria-hidden="true"></i>
                          </div>
                          <div class="recent-searches ml-1">
                            <p>{{ airport.city }}, {{ airport.country }}</p>
                            <p class="blackText">{{ airport.name }}</p>
                          </div>
                          <div class="searches-code"><p>{{ airport.code }}</p></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li id="liArrival" data-trigger="manual" data-toggle="popover" data-placement="top" data-content="<span><i class='fa fa-exclamation-triangle'></i> Enter valid From airport</span>">
                <a (click)="arrivalClick()">
                  <div class="book-details book-details2" >
                    <h5 id="arrivalDatah5">To</h5>
                    <input id="arrivalDatainput" type="text" class="mehman-inputfiled" [value]="destinationLocation" readonly />
                    <p id="arrivalP">{{ destinationCountry }}</p>
                  </div>
                </a>
                <div class="inputfiled-formboxes" id="locationContainer2">
                  <div class="inputfiled-formbox">
                    <div class="input-search">
                      <input type="text" id="txtFrom" class="input-from" placeholder="To" [(ngModel)]="destinationLocation" (ngModelChange)="filterAirports()" />
                      <div class="search-icon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <ul>
                      <li><p class="recent-tag">Popular Cities</p></li>
                      <li *ngFor="let airport of filteredAirports" role="option" style="cursor: pointer" (click)="selectedDestination(airport.city)">
                        <div class="hrtlCenter">
                          <div class="search-icon">
                            <i class="fa fa-plane" aria-hidden="true"></i>
                          </div>
                          <div class="recent-searches ml-1">
                            <p>{{ airport.city }}, {{ airport.country }}</p>
                            <p class="blackText">{{ airport.name }}</p>
                          </div>
                          <div class="searches-code"><p>{{ airport.code }}</p></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li class="datess" style="cursor: pointer" (click)="Packageopen()">
                <div class="book-details">
                  <h5 id="Package-h5">Select Package</h5>
                  <h2 id="Package-h2">{{ selectedPackage }}</h2>
                  <p style="opacity: 0">o</p>
                </div>
              </li>
              <div class="Package-container" *ngIf="Package">
                <div class="price-range" (click)="selectPackage('Economy-package')">
                  Economy package
                </div>
                <div class="price-range" (click)="selectPackage('3-star')">
                  3 star
                </div>
                <div class="price-range" (click)="selectPackage('4-star')">
                  4 star
                </div>
                <div class="price-range" (click)="selectPackage('5-star')">
                  5 star
                </div>
                <div class="price-range" (click)="selectPackage('7-star')">
                  7 star
                </div>
              </div>
              <li class="datess" style="cursor: pointer" (click)="NumofdaysOpen()">
                <div class="book-details">
                  <h5 id="days-h5">Select Duration</h5>
                  <h2 id="days-h2">{{ selectedNumofdays  }}</h2>
                  <p style="opacity: 0">o</p>
                </div>
              </li>
              <div class="days-container" *ngIf="Numofdays">
                <div class="price-range" (click)="selectdays('1-7')">
                  1 - 7 days
                </div>
                <div class="price-range" (click)="selectdays('8-14')">
                  8 - 14 days
                </div>
                <div class="price-range" (click)="selectdays('15-21')">
                  15 - 21 days
                </div>
                <div class="price-range" (click)="selectdays('22-28')">
                  22 - 28 days
                </div>
              </div>
              <li class="datess" style="cursor: pointer" (click)="nightopen()">
                <div class="book-details">
                  <h5 id="price-h5">Price Per Night</h5>
                  <h2 id="price-h2">{{ selectedPriceRange  }}</h2>
                  <p style="opacity: 0">o</p>
                </div>
              </li>
              <div class="priceRang-container" *ngIf="priceNight">
                <div class="price-range" (click)="selectPriceRange('10000-50000')">
                  10000 - 50000
                </div>
                <div class="price-range" (click)="selectPriceRange('50000-250000')">
                  50000 - 250000
                </div>
                <div class="price-range" (click)="selectPriceRange('350000-500000')">
                  350000 - 500000
                </div>
                <div class="price-range" (click)="selectPriceRange('500000-2000000')">
                  500000 - 2000000
                </div>
              </div>
              <li class="result-btn p-1">
                <div class="book-details">
                  <a class="btn" (click)="searchResult()">SEARCH</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero-filter">
  <div class="container">
 
    <div class="row justify-content-center">
      <!-- <div class="col-lg-3 col-sm-12 col-xs-12">
        <div class="header-offer-box sort-filter wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Filter by</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <h5>Suggest for you</h5>

              <label class="containerlabel">
                New Deals First
                <input type="radio" id="new-deals-first" name="sort-by" value="NewDealsFirs" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Price Low to High
                <input type="radio" id="price-low-to-high" name="sort-by" value="PriceLow" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Price High to Low
                <input type="radio" id="price-high-to-low" name="sort-by" value="PriceHigh" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="flt-check">
              <h5>Distance from Masjid Nabawi</h5>

              <label class="containerlabel">
                All
                <input type="radio" id="distance-all" name="distance-from-haram" value="all" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Within 300m
                <input type="radio" id="distance-300m" name="distance-from-haram" value="300" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                300m to 600m
                <input type="radio" id="distance-600m" name="distance-from-haram" value="600" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                600m to 1Km
                <input type="radio" id="distance-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                More than 1Km
                <input type="radio" id="distance-more-than-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="flt-check">
              <h5>Distance from Haram</h5>

              <label class="containerlabel">
                All
                <input type="radio" id="distance-all" name="distance-from-haram" value="all" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Within 300m
                <input type="radio" id="distance-300m" name="distance-from-haram" value="300" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                300m to 600m
                <input type="radio" id="distance-600m" name="distance-from-haram" value="600" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                600m to 1Km
                <input type="radio" id="distance-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                More than 1Km
                <input type="radio" id="distance-more-than-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-lg-11 col-sm-12 col-xs-12">
        <ng-container>
          <ng-container *ngIf="searchResults && searchResults.length">
            <h1>{{ searchResults.length }} properties found</h1>
            <div *ngFor="let result of searchResults" class="umrah-card row wow fadeIn mt-3">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <!-- <img class="img-main" [src]="result.imageSrc" alt="Image"/> -->
                <img class="img-main" [src]="mainImageSrc" alt="Image" />
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12" (click)="details(result.id)">
                <h3 class="fw-500">{{ result.tripName }}</h3>
                <div class="d-flex justify-content-between">
                  <div>
                    <span><i class="fa fa-building" aria-hidden="true"></i> From:
                      {{ result.originLocation }}</span><br />
                    <span><i class="fa fa-road" aria-hidden="true"></i>
                      {{ result.transport }}
                    </span><br/>
                    <span><i class="fa fa-sun-o" aria-hidden="true"></i>
                      {{ result.duration }} Nights</span>
                  </div>
                  <div>
                    <span><i class="fa fa-building" aria-hidden="true"></i> To:
                      {{ result.destinationLocation }}</span><br />
                    <span><i class="fa fa-star" aria-hidden="true"></i>
                      {{ result.starCategory }} </span><br />
                   
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <h1 class="white text-center">{{ result.price }}<small>/Rs</small></h1>
                <button class="Detials" style="cursor: pointer" (click)="opendialog()">
                  Book Now
                </button>
              </div>
            </div>
          </ng-container>
          <div class="card flex justify-content-center dialog">
            <p-dialog
              header=""
              [(visible)]="visible"
              [style]="{ width: '90vw', maxWidth: '50rem' }"
              (onHide)="dialogClosed()"
            >
            
            <div class="popup-content text-center">
              <h2>For booking please contact this number</h2>
              <!-- Optionally, include the actual contact number -->
              <p>0325488818</p>
            </div>
            
            </p-dialog>
          </div>
          <!-- <div class="">
            <h1>Umrah: 37 properties found</h1>
            <div class="umrah-card row wow fadeIn" (click)="packageDetails()">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <img class="img-main" [src]="mainImageSrc" />
              </div>
  
              <div class="col-lg-6 col-md-6 col-sm-12">
                <h3 class="fw-500">Jabal Omar Marriott Hotel Makkah</h3>
                <div class="d-flex justify-content-between">
                  <div>
                    <h4 class="fw-500">Makkah hotel</h4>
                    <span><i class="fa fa-building" aria-hidden="true"></i> From:
                      All Airport</span><br />
                    <span><i class="fa fa-sun-o" aria-hidden="true"></i> 5
                      Nights</span>
                    <br />
                    <span><i class="fa fa-road" aria-hidden="true"></i> 1800
                      meter</span>
                  </div>
                  <div>
                    <h4 class="fw-500">Makkah hotel</h4>
                    <span><i class="fa fa-building" aria-hidden="true"></i> From:
                      All Airport</span><br />
                    <span><i class="fa fa-sun-o" aria-hidden="true"></i> 5
                      Nights</span>
                    <br />
                    <span><i class="fa fa-road" aria-hidden="true"></i> 1800
                      meter</span>
                  </div>
                  <div></div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <h1 class="white"><small>from Rs </small>38,000</h1>
                <button class="Detials" style="cursor: pointer" (click)="packageDetails()">
                 Book Now
                </button>
              </div>
            </div>
          </div> -->
        </ng-container>
      </div>
    </div>
  </div>
</section>