import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiCallService } from './api-call.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResultModel } from '../models/api-result.model';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {
  private getTripPackageUrl = environment.adminApiUrl + '/TripPackages/GetAll'
  private SearchTripPackagesUrl = environment.adminApiUrl + '/TripPackages/SearchTripPackages';
  private getByIdUrl = environment.adminApiUrl + '/TripPackages/GetById'


  constructor(private api: ApiCallService,private http: HttpClient) {}
  private holidayParams = new BehaviorSubject<any>(null);
  private searchResults: any[] = [];
  public selectedPriceRange: string = 'Any';
  public selectedPackage: string = 'All Packages';
  public selectedNumofdays: string = 'All Duration';
  public destinationLocation: string = 'Lahore';
  public originLocation: string = 'Karachi';

  setSearchResults(results: any[]) {
    this.searchResults = results;
  }

  getSearchResults(): any[] {
    return this.searchResults;
  }
  setHolidayParams(params: any) {
    this.holidayParams.next(params);
  }

  getHolidayParams(): Observable<any> {
    return this.holidayParams.asObservable();
  }
 
  getTripPackages(): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      this.getTripPackageUrl,
      'Get TripPackages',
      new ApiResultModel()
    );
  }
  getGetById(id: any): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      `${this.getByIdUrl}?id=${id}`,
      'Get trip',
      new ApiResultModel()
    );
  }
 
  searchtrip(TripPackages: any): Observable<ApiResultModel> {
    return this.api.post<ApiResultModel>(
      this.SearchTripPackagesUrl,
      TripPackages
    );
  }
 
}
